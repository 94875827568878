.profile {
  .field {
    margin-bottom: 10px;

    h3 {
      font-weight: normal;
    }
  }
  .field-name-field-first-name,
  .field-name-field-middle-name,
  .field-name-field-last-name {
    @include breakpoint($screen-s) {
      display: none;
    }
  }
  .field-pseudo-field--full-name {
    display: none;
    @include breakpoint($screen-s) {
      display: block;
    }
  }
  .field-pseudo-field--change-email-info {
    margin: 30px 0px;
  }
}
.page-user {
  .tabs.primary {
    border: none;
    text-align: center;
    margin-bottom: 30px;

    li {
      margin: 0px 10px;
    }
    li a {
      @extend %small-button;
      background-color: $grey-lightest;
      color: $black;

      &.active,
      &:hover,
      &:focus {
        border: none;
        margin: 0;
        background-color: $action-color;
        color: $white;
      }
    }
  }
  .page-title-wrapper {
    margin-top: 20px;

    @include breakpoint($screen-s) {
      margin-top: 0px;
    }
  }
  .back-to-play-button {
    position: relative;
    z-index: 980;
    margin: 40px 20px -40px 20px;

    @include breakpoint($screen-s) {
      margin: 70px 20px -70px 20px;
    }
    @include breakpoint($screen-l) {
      margin: 40px 20px -40px 20px;
    }
  }
}
