.page-classroom-quiz-answers {
  .content {
    max-width: 1024px;
    padding-bottom: 50px;
  }
  .back-to-classroom-link {
    padding-left: 15px;
  }
  .page-title {
    display: block;
  }
  .views-js-controls {
    padding-bottom: 15px;
    text-align: right;
  }
  .classroom-quiz-answers-filters {
    display: flex;
    column-gap: 15px;
    flex-direction: column;
    padding: 15px;
    @include breakpoint($screen-m) {
      flex-direction: row;
    }
    select {
      width: 100%;
      min-width: 200px;
      padding: 8px 5px;
      margin: 0;
      color: $red;
      height: 49px;
      box-sizing: border-box;
      outline: none;
      @include appearance(none);
      background-image: url('../images/dropdown-arrow.png');
      background-repeat: no-repeat;
      background-position: right 5px center;
      option {
        color: $black;
        background-color: $white;
      }
    }
    label {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: normal;
      line-height: 18px;
    }
  }
  .form-submit {
    position: relative;
    top: -5px;
    align-self: center;
    margin: 0px 10px;
    padding: 14px 16px;
    font-size: 16px;
    width: 150px;
  }
  #edit-clear {
    width: max-content;
    padding-left: 0;
    padding-right: 0;
    background: none;
    @extend a;
  }
  .form-item {
    margin-top: 0;
  }
  .classroom-quiz-answers-display {
    .classroom-quiz-answers-question {
      padding-right: 40px;
      background-color: $grey-table;
      border-bottom: 2px solid $grey-lighter;
      cursor: pointer;
      &::after {
         content: url('../images/icon-plus.svg');
         position: absolute;
         right: 12px;
         top: 15px;
      }
      &.is-toggled {
        color: $dark-red;
        &::after {
          content: url('../images/icon-minus.svg');
          top: 9px;
        }
      }
    }
    .classroom-quiz-answers-question-details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 15px;
      color: $red;
      font-size: 20px;
      text-align: left;
      &:hover {
        color: $dark-red;
      }
    }
    .classroom-quiz-answers-question-details-play {
      padding-bottom: 15px;
      padding-right: 5px;
      font-weight: bold;
      &::after {
        content: ':';
      }
    }
    .classroom-quiz-answers-question-text {
      width: 100%;
      color: $black;
      font-size: 16px;
    }
    .classroom-quiz-answers-answers {
      display: none;
      font-size: 14px;
    }
    .classroom-quiz-answers-answer {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 10px 15px;
      text-align: left;
      border-bottom: 1px solid $grey-lighter;
    }
    .answer-name {
      width: 30%;
      padding-right: 15px;
      color: $black;
    }
    .answer-answer {
      width: 100%;
    }
  }
}