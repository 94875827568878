.quick-nav {
  position: fixed;
  top: 72px;
  width: 100%;
  right: 0;
  left: 0;
  background: $grey-lightest;
  z-index: 970;
  -webkit-box-shadow: 0px 2px 20px 0px $grey-light;
     -moz-box-shadow: 0px 2px 20px 0px $grey-light;
          box-shadow: 0px 2px 20px 0px $grey-light;

  @include breakpoint($screen-l) {
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
  }
}
.admin-menu {
  .quick-nav {
    top: 100px;
    @include breakpoint($screen-l) {
      top: 80px;
    }
  }
}
