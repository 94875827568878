.node-type-video-index-item {
  .group-headline-and-type {
    margin-bottom: 5px;
  }
  .node-video-index-item {
    .field-name-field-index-introduction {
      padding-left: 0px;
    }
    .lines-renderer-wrapper {
      &:before {
        @extend h3.field-label;
        content: "Shakespeare's Text";
      }
      .word.hidden {
        display: none;
      }
    }
  }
  .line-number {
    right: 0;
  }
  .block-paradigm-qs-ft-toggle-back-to-full-text {
    display: block;
    position: relative;
    top: auto;
    width: 100%;
    margin-bottom: 50px;
    .toggle-link-qs {
      position: absolute;
      right: 0;
    }
  }
}
