.file-video {
  max-width: 100%;
  width: 640px;
  margin-left: auto;
  margin-right: auto;
  .media-vimeo-video {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
