fieldset {
  background-color: lighten($base-border-color, 10%);
  border: $base-border;
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 3;
  transition: border-color;
  width: 100%;

  &:hover {
    border-color: darken($base-border-color, 10%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

input[type="file"] {
  padding-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 99%;
  width: auto;
}

%styled-checkbox {
  input {
    opacity: 0;
    margin: 0;
  }
  label {
    position: relative;
    display: inline-block;
    padding-left: 27px;
    padding-top: 5px;

    &::before,
    &::after {
      position: absolute;
      content: "";
      display: inline-block;
    }
    &::before {
      height: 18px;
      width: 18px;
      border: 1px solid;
      left: 0px;
      top: 3px;
    }
    &::after {
      height: 7px;
      width: 11px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
      content: none;
    }
  }
  input:checked + label::after {
    content: "";
  }
  input:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
}
%notebook-checkbox {
  display: flex;
  align-items: center;
  @extend %styled-checkbox;
  label {
    font-size: 12px;
    padding-right: 5px;
  }
  label::before {
    background-color: $white;
    border-color: $grey-light;
    border-radius: 3px;
  }
  input:checked + label::before {
    background-color: $red;
    border-color: $red;
  }
  input:checked + label::after {
    color: $white;
  }
}
