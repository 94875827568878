.block-blockpop {
  display: none;

  h2 {
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: 0.23px;
    text-align: center;
    color: $red;
  }
}
