/* Styling for mobile menu mmenu */
.block-paradigm-mmenu-mmenu {
  display: none;
}
.block-paradigm-mmenu-mmenu-hamburger {
  @include breakpoint($screen-xl) {
    display: none;
  }
}
.mmenu-hamburger {
  .icon-close {
    display: none;
    width: 26px;
    height: 26px;
    background: url('../images/icon-x-white.svg') 50% 50% no-repeat;
    background-size: 20px;
  }
  &.is-open {
    .icon-open {
      display: none;
    }
    .icon-close {
      display: inline-block;
    }
  }
}

body.admin-menu {
  .mm.mm--offcanvas {
    top: 122px;
  }
}
.mm {
  --mm-width: 100%;
  --mm-max-width: 100%;
  &.mm--offcanvas {
    top: 72px;
  }
  background-color: $red;
  color: $white;
  li:before {
    opacity: 1;
  }
  li:after {
    border-top: 2px solid;
  }
  a,
  span,
  li > div {
    padding: 13px 20px;
  }
  .react-toggle {
    width: 100%;
    background: inherit;
    padding: 0;
    &,
    span {
      display: inline-block;
    }
    span {
      padding: 0;
    }
  }
  .react-toggle-indicator {
    margin-left: 1ex;
    top: 4px;
    background-color: $white;
    color: $red;
  }
  .mmenu-item-gloss-toggle {
    .react-toggle {
      min-width: 180px;
      color: $white;
    }
    .react-toggle-indicator:before {
      background-color: $red;
    }
    div {
      padding-right: 10px;
    }
    &:before,
    &:after {
      display: none;
    }
  }
  .mmenu-item-search,
  .mmenu-item-features,
  .mmenu-item-about,
  .mmenu-item-blog {
    a:active,
    a:hover {
      color: $white;
    }
  }
  span span {
    padding: 0;
    display: inline;
  }

  #mmenu-play-menu {
    .font-ivory-headline {
      font-size: 1.375rem;
    }
  }

  #mmenu-item-notebook,
  .mmenu-item-scene-summary,
  .mmenu-item-scene-summary-content,
  .mmenu-item-videos,
  .mmenu-item-videos-content {
    background: $grey-lightest;
    color: $red;
  }

  .mmenu-item-scene-summary-content {
    color: $black;
  }

  #mmenu-item-notebook {
    a {
      position: static;
    }
  }

  .mmenu-item-scene-summary,
  .mmenu-item-videos {
    &:before {
      display: none;
    }

    .nav-arrow {
      position: absolute;
      top: calc(var(--mm-item-height)/ 2);
      right: calc(var(--mm-item-height)/ 2);
      width: 10px;
      height: 10px;
      padding: 0;
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(135deg) translate(-50%, 0);
    }

    &.expanded {
      .nav-arrow {
        transform: rotate(-45deg) translate(50%, 0);
      }
    }
  }

  .mmenu-item-scene-summary-content,
  .mmenu-item-videos-content {
    display: none;
    cursor: auto;

    &:before {
      display: none;
    }

    .contextual-links-wrapper {
      display: none;
    }
  }

  .mmenu-item-videos-content {
    .chosen-processed {
      ul {
        left: 0;

        &:after {
          display: none;
        }
      }
      li {
        &:before,
        &:after {
          display: none;
        }
      }

      .chosen-results {
        width: auto;
      }

      .chosen-choices {
        top: 0;

        span {
          padding: 0;
        }

        .search-choice-close {
          padding: 0;
        }
      }
    }

    @include video-digest-view;
  }
}
.paradigm-search-form-mobile {
  position: relative;
  max-width: 250px;

  @media all and (max-width: $screen-s) {
    max-width: 100%;
  }

  .form-item-search {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 25px;
    label {
      display: none;
    }
  }
  .form-text {
    &, &:focus, &:active {
      display: block;
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      color: $white;
      padding: 0;
      border-bottom: $white 2px solid;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 40px;
    }
  }
  .form-submit {
    position: absolute;
    right: 0;
    top: 0px;
    width: 45px;
    height: 42px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: initial;
    background-image: url('../images/spyglass.png');
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
  }
}
.mmenu-hamburger {
  padding: 10px 15px;
  font-size: 26px;
  line-height: 30px;
  display: block;
}
.mm-blocker {
  display: none;
}
.mm-body--open {
  pointer-events: auto;
}
.mm--open {
  &:-webkit-scrollbar {
    display: none;
  }
}

.fancybox-overlay {
  z-index: 10000;
}
.fancybox-opened {
  z-index: 10010;
}

.notebook-link.disabled {
  color: $grey-dark;
}
